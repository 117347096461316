<template>
    <div class="flex flex-col min-h-screen">

        <!-- <SLanguageHeader /> -->
        <SHeaderCMS :navigationItems="navigationItems" :iconItems="iconItems">
        </SHeaderCMS>
        <main class="flex-grow">
            <slot />
        </main>
        <SFooterSmall>
            <a :href="privacyPolicyLink" class="footer-link">{{ $t("privacy_policy") }}</a>
            <a :href="legalNoticeLink" class="footer-link">{{ $t("imprint") }}</a>
            <a v-if="locale === 'de-DE'" href="https://www.scheppach.com/de/footer-links/garantiebedingungen"
                class="footer-link">{{ $t("warranty_conditions") }}</a>
        </SFooterSmall>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t, locale } = useI18n();

const legalNoticeLink = computed(() => {
    switch (locale.value) {
        case "de-DE":
            return "https://www.scheppach.com/de/impressum"
        case "fr-FR":
            return "https://www.scheppach.com/fr/mentions-legales"
        default:
            return "https://www.scheppach.com/en/legal-note"
    }
});

const privacyPolicyLink = computed(() => {
    switch (locale.value) {
        case "de-DE":
            return "https://www.scheppach.com/de/datenschutz"
        case "fr-FR":
            return "https://www.scheppach.com/fr/regles-de-confidentialite"
        default:
            return "https://www.scheppach.com/en/data-protection"
    }
});

const metaItems = [
    { route: "https://shop.scheppach.com/#", icon: "business", text: "Direkt vom Hersteller" },
    { route: "https://shop.scheppach.com/b-ware", icon: "check_circle", text: "Geprüfte B-Ware & Neuware" },
    {
        route: "https://shop.scheppach.com/versand-und-rueckgabe",
        icon: "local_shipping",
        text: "Kostenloser Versand ab 50€ (DE)*",
    },
];

const navigationItems = computed(() => [
    { text: t("product_registration") }
]);

const iconItems = [
    // { route: "/search", icon: "search" },
    // { route: "/account", icon: "person" },
    // { route: "/cart", icon: "shopping_cart" },
];
</script>

<style scoped>
.footer-link {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    text-decoration-line: underline;
    color: white;
}
</style>